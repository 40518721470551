<template>
  <div>
    <div class="header">
      <span></span>
      <span>{{ $t("lang.PackageCenter") }}</span>
    </div>
    <div class="hengxiangs">
      <!-- <img class="imglogo" :src="itemsobg.logo" alt="" /> -->
      <div
        class="imglogo"
        :style="'background: url(' + itemsobg.logo + ') no-repeat;'"
      ></div>
      <div class="conter">
        <div class="uls">
          <div class="lis">
            <div class="namesli">{{ $t("lang.Packagename") }}：</div>
            <div class="rightli">{{ itemsobg.name }}</div>
          </div>
          <div class="lis">
            <div class="namesli">{{ $t("lang.Flowlimit") }}：</div>
            <div class="numbers1">{{ itemsobg.flowQuota }}GB</div>
            <div class="namesli">{{ $t("lang.storagequota") }}：</div>
            <div class="numbers1 borderNOne">{{ itemsobg.storageQuota }}GB</div>
          </div>
          <div class="lis">
            <div class="namesli">{{ $t("lang.Costunitprice") }}：</div>
            <div class="numbers1">
              {{ itemsobg.chargingUnit }}{{ itemsobg.unitPrice }}
            </div>
            <div class="namesli">{{ $t("lang.Excesstrafficstrategy") }}：</div>
            <div class="numbers1 borderNOne">
              {{
                itemsobg.flowStrategy == 1
                  ? $t("lang.Additionalbilling")
                  : $t("lang.OutofService")
              }}
            </div>
          </div>
          <div class="lis">
            <div class="namesli">{{ $t("lang.Months") }}：</div>
            <div class="numbers1">{{ itemsobg.unit }}</div>
            <div class="namesli">{{ $t("lang.danjiafujia") }}：</div>
            <div class="numbers1 borderNOne">
              <span v-if="itemsobg.flowAdditionalUnit"
                >{{ itemsobg.chargingUnit
                }}{{ itemsobg.flowAdditionalUnit }}/Gbps</span
              >
              <span v-else>--</span>
            </div>
          </div>
          <div class="lis">
            <div class="namesli">{{ $t("lang.zongjia") }}：</div>
            <div class="numbers1">
              {{ itemsobg.chargingUnit
              }}{{ Number(itemsobg.unit) * Number(itemsobg.unitPrice) }}
            </div>
            <div class="namesli">{{ $t("lang.cucunxiane") }}：</div>
            <div class="numbers1 borderNOne">
              {{
                itemsobg.storageStrategy == 1
                  ? $t("lang.Additionalbilling")
                  : $t("lang.OutofService")
              }}
            </div>
          </div>
          <div class="lis">
            <div class="namesli">{{ $t("lang.Discountfee") }}：</div>
            <div class="numbers1">
              {{ itemsobg.chargingUnit }}{{ itemsobg.discountPrice }}
            </div>
            <div class="namesli">{{ $t("lang.fujiacucen") }}：</div>
            <div class="numbers1 borderNOne">
              <span v-if="itemsobg.storageAdditionalUnit"
                >{{ itemsobg.chargingUnit
                }}{{ itemsobg.storageAdditionalUnit }}/Gbps</span
              >
              <span v-else>--</span>
            </div>
          </div>
          <!-- <div class="lis">
            <div class="namesli">{{ $t("lang.Discountfee") }}：</div>
            <div class="numbers1">
              {{ itemsobg.startTime }}
            </div>
            <div class="namesli">{{ $t("lang.fujiacucen") }}：</div>
            <div class="numbers1 borderNOne">
              <span v-if="itemsobg.endTime">{{ itemsobg.endTime }}</span>
              <span v-else>--</span>
            </div>
          </div> -->
          <div class="lis">
            <div class="namesli">{{ $t("lang.shijia") }}：</div>
            <div class="numbers1">
              {{ itemsobg.chargingUnit }}{{ itemsobg.allPrice }}
            </div>
            <div class="namesli borderNOne"></div>
            <div class="numbers1 borderNOne"></div>
          </div>
        </div>
        <div class="xuzhis">
          <el-checkbox v-model="checked">
            <span style="color: #c2c2c2">{{ $t("lang.woyuedu") }}</span
            ><span style="color: #307b37" @click="yonghuadds">{{
              $t("lang.xiangxiyuedule")
            }}</span>
          </el-checkbox>
        </div>
        <div class="btnlist">
          <div class="buttons" @click="returns">
            {{ $t("lang.return") }}
          </div>
          <div class="button" @click="invest">
            {{ $t("lang.Buynow") }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="
        actives == 1 ? $t('lang.Serviceinstructions') : $t('lang.Purchasetips')
      "
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="delete" style="margin-bottom: 0.2rem">
        <span v-if="actives == 1">{{ itemsobg.notice }}</span>
        <span v-if="actives == 2">{{ itemsobg.tips }}</span>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" v-if="actives == 1" @click="cardgoumaiads">
            <p>{{ $t("lang.Ihaveread") }}</p>
          </button>
          <button class="button_a" v-if="actives == 2" @click="cardgoumai">
            <p>{{ $t("lang.already") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
    <!-- 确认密码 -->
    <el-dialog
      :title="$t('lang.Pleaseenternumbers')"
      :visible.sync="showSees"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="delete" style="margin-bottom: 0.2rem">
        <el-input
          v-model="password"
          type="password"
          :placeholder="$t('lang.Pleaseenternumbers')"
        ></el-input>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="quxiaoomodas">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.confirm") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
import * as contract from "@/api/contract.js";
export default {
  props: ["itemsobg"],
  data() {
    return {
      checked: false,
      radio: 1,
      centerDialogVisible: false,
      actives: 1,
      showSees: false,
      password: "",
    };
  },
  methods: {
    //   打开服务须知
    yonghuadds() {
      this.centerDialogVisible = true;
      this.actives = 1;
    },
    //   打开购买须知
    invest() {
      if (!this.checked) {
        this.$message.error(this.$t("lang.firstname"));
        return;
      }
      this.centerDialogVisible = true;
      this.actives = 2;
    },
    cardgoumaiads() {
      this.centerDialogVisible = false;
      this.checked = true;
    },
    //   立即购买
    cardgoumai() {
      this.centerDialogVisible = false;
      this.showSees = true;
    },
    // 取消
    quxiaoomodas() {
      this.password = "";
      this.showSees = false;
    },
    //   确认
    async determine() {
      const jse = new JSEncrypt();
      const publiceKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
      jse.setPublicKey(publiceKey);
      let data = {
        mealId: this.itemsobg.id,
        password: jse.encrypt(this.password),
      };
      let res = await contract.userMealbuy(data);
      console.log(res.data);
      let sk = res.msg;
      if (res.msg) {
        let wq = sk.split("-");
        if (
          sessionStorage.getItem("language") &&
          sessionStorage.getItem("language") == "zhong"
        ) {
          sk = wq[0];
        } else {
          sk = wq[1];
        }
        this.$message.success(sk);
      }
      this.showSees = false;
      // this.$message.success(this.$t("lang.Operations"));
      let obg = {
        type: 1,
        id: 0,
      };
      this.$emit("cheactive", obg);
    },
    //   返回
    returns() {
      let obg = {
        type: 0,
        id: 0,
      };
      this.$emit("cheactive", obg);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #307b37 !important;
  background-color: #307b37 !important;
}
/deep/.el-checkbox__inner:hover {
  border-color: #307b37 !important;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #307b37 !important;
}
.header {
  font-size: 0.16rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #307b37;
  span:first-child {
    display: inline-block;
    width: 0.04rem;
    height: 0.2rem;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 0.07rem;
  }
}
.hengxiangs {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.25rem;
  .imglogo {
    width: 20%;
    margin-right: 0.2rem;
    height: 3rem;
    background-position: center center;
    background-size: cover;
  }
}
.conter {
  //   padding-left: 0.9rem;
  //   margin-top: 0.4rem;
  font-size: 0.16rem;
  color: #c2c2c2;
  width: 80%;
  > div {
    margin-bottom: 0.2rem;
  }
  .monyle {
    display: flex;
    align-items: center;
    .monyle_a {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      div {
        width: 1rem;
        height: 0.36rem;
        border: 1px solid #c2c2c2;
        line-height: 0.36rem;
        text-align: center;
        margin-right: 0.15rem;
        border-radius: 2px;
        cursor: pointer;
      }
      .actives {
        border: 1px solid #307b37;
        color: #307b37;
      }
    }
  }
  /deep/.monsl {
    .el-input__inner {
      background-color: #171b1e;
      border: 1px solid #c2c2c2;
      color: #c2c2c2;
    }
  }
  .tips {
    font-size: 0.14rem;
    color: #7f7f7f;
    display: inline-block;
    margin-left: 0.2rem;
  }
}
.btnlist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button {
  width: 1.1rem;
  height: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  box-sizing: border-box;
  background-color: #307b37;
  font-size: 0.16rem;
  color: #ffffff;
  border-radius: 3px;
  margin-left: 0.8rem;
  cursor: pointer;
}
.buttons {
  width: 1.1rem;
  height: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  box-sizing: border-box;
  background: #222628;
  font-size: 0.16rem;
  color: #ffffff;
  border-radius: 3px;
  //   margin-left: 0.8rem;
  cursor: pointer;
  border: 0.01rem solid #307b37;
  color: #307b37;
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.4rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: 0.02rem;

  p {
    font-size: 0.18rem;
    font-weight: 500;
    color: #438749;
    line-height: 0.3rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.4rem;
  background: #438749;
  border-radius: 0.02rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.3rem;
  }
}
.xuzhis {
  // text-align: center;
}
.uls {
  width: 100%;
  border: 1px solid #c2c2c2;
  text-align: center;
  .lis {
    border-bottom: 1px solid #c2c2c2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .namesli {
      width: 25%;
      padding: 10px 0;
      border-right: 1px solid #c2c2c2;
    }
    .rightli {
      width: 75%;
    }
    .numbers1 {
      width: 25%;
      padding: 10px 0;
      border-right: 1px solid #c2c2c2;
    }
    .borderNOne {
      border: none;
    }
  }
}
</style>