<template>
	<div class="contents">
		<div class="header">
			<span></span>
			<span>{{$t('lang.Billdetails')}}</span>
		</div>
		<div class="searchlist">
			<div class="searchtime" style="margin-right: .4rem">
			  <span style="color: #bbbbbb">{{$t('lang.consumptiontime')}}：</span>
			  <el-date-picker v-model="value1" type="daterange" :range-separator="$t('lang.to')" :start-placeholder="$t('lang.Startdate')"
			    :end-placeholder="$t('lang.Enddate')" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
			    @change="times">
			  </el-date-picker>
			</div>
			<div class="queryss">
			  <div class="inputname">
			    <input v-model="parsm.keyword" :placeholder="$t('lang.Pleasebillnumber')"/>
			  </div>
			  <div class="searchbtn">
			    <button @click="search">{{$t('lang.query')}}</button>
			  </div>
			</div>
		</div>
		<div class="tabelss">
			<el-table :data="tableData.content" style="width: 100%">
				<el-table-column align="center" :label="$t('lang.paymentdays')" width="150">
					<template slot-scope="scope">
					  <span>{{scope.row.startTime | times}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.accountnumber')" width="180">
				</el-table-column>
				<el-table-column prop="sn" align="center" :label="$t('lang.billnumber')" width="180">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Thebilltype')" width="150">
					<template slot-scope="scope">
					  <span v-if="scope.row.type==0">{{$t('lang.Flowbill')}}</span>
					  <span v-else>{{$t('lang.Storebills')}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.consumptiontime')" width="350">
					<template slot-scope="scope">
					  <span>{{scope.row.startTime}}——</span>
					  <span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.unitprice')" width="120">
					<template slot-scope="scope">
					  <span>{{scope.row.unitPrice}}RMB/GB</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.dosage')" width="120">
					<template slot-scope="scope">
					  <span>{{scope.row.amount |amoutsa}}GB</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Amountpayable')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.money |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.paymentamount')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.payPrice |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Owetheamount')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.arrearsPrice |monsa}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="balance" align="center" :label="$t('lang.accountbalance')" width="150">
				</el-table-column> -->
				<el-table-column :label="$t('lang.operation')" align="center" width="120">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click='guide(scope.row)'>{{$t('lang.details')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right" class="paginatss" v-if="tableData.content && tableData.content.length > 0">
			  <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.totalElements}} <span>{{$t("lang.Strip")}}</span></div>
			  <el-pagination background layout="prev, pager, next" :total="tableData.totalElements" @current-change="handleChange"
			    :prev-text="$t('lang.previouspage')"
			    :next-text="$t('lang.nextpage')">
			  </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import * as check from "@/api/order";
	export default{
		data() {
			return{
				parsm:{
					page:1,
					size:10,
					type:0,
					keyword:'',
					time:'',
					stime:'',
					etime:'',
					email:''
				},
				value1:'',
				tableData:'',
				
			}
		},
		created() {
			this.list()
			if(this.$route.query.time){
				this.parsm.time=this.$route.query.time
				this.parsm.type=this.$route.query.type
			}
		},
		filters:{
			times(val){
				let skr=''
				if(val){
					let sk=val.split(' ')
					let skk=sk[0].split('-')
					skr=skk[0]+'-'+skk[1]
				}
				return skr
			},
			amoutsa(val){
				if(val){
					return val.toFixed(4)
				}else{
					return val
				}
			},
			monsa(val){
				if(val){
					return val.toFixed(2)
				}else{
					return val
				}
			}
		},
		methods:{
			//获取详细账单列表
			async list(){
				let res=await check.detalist(this.parsm)
				console.log(res)
				this.tableData=res.data
			},
			//时间筛选
			times(e){
				this.parsm.page=1
				this.parsm.size=10
				if(e){
					this.parsm.stime=e[0]
					this.parsm.etime=e[1]
				}else{
					this.parsm.stime=''
					this.parsm.etime=''
				}
				this.list()
			},
			//账单类型筛选
			flow(e){
				this.parsm.page=1
				this.parsm.size=10
				this.parsm.type=e
				this.list()
			},
			//订单编号筛选
			search(e){
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//分页页数改变
			handleChange(page){
				this.parsm.page=page
				this.list()
			},
			//详情
			guide(row){
				if(row.type==1){
					let stimes=row.startTime.split(' ')[0]
					let etimes=row.endTime.split(' ')[0]
					this.$router.push({
						path:'/nav/personal',
						query:{ind:2,inds:1,etime:etimes,stime:stimes,type:row.type}
					})
				}else{
					this.$router.push({
						path:'/nav/personal',
						query:{ind:2,inds:1,sn:row.sn,type:row.type}
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.contents{
		padding: .35rem .45rem;
		box-sizing: border-box;
		.header{
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
		.searchlist{
			margin-top: .28rem;
			font-size: .16rem;
			display:flex;
			flex-flow: row;
			justify-content: flex-start;
			color: #c2c2c2;
			align-items: center;
			margin-bottom: .28rem;
			.querys{
				display: flex;
				align-items: center;
				margin-right: .4rem;
				>label{
					margin-right: .1rem;
				}
				/deep/.el-radio__label{
					color: #C2C2C2;
				}
				/deep/ .el-radio__input.is-checked .el-radio__inner{
					border-color: #307B37;
					background: #307B37;
				}
				/deep/ .el-radio__input.is-checked+.el-radio__label{
					color: #307B37;
				}
			}
		}
	}
	/deep/.tabelss{
		.el-table{
			border: 1px solid #9A9A9A;
			.has-gutter tr th{
				background-color: #222628;
				color: #c2c2c2;
				border-color: #9A9A9A;
			}
		}
		.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
			border-color: #9A9A9A;
		}
		.el-table::before{
			display: none;
		}
		.el-table tbody tr {
		   background: #171B1E;
		    width: 100%;
		    font-size: .14rem;
		    font-family: PingFang SC;
		    font-weight: 500;
		    color: #c2c2c2;
				td{
					border-color: #9A9A9A;
				}
		}
		.el-table tbody tr:last-child{
			td{
				border-bottom: none;
			}
		}
		.el-table tbody tr:hover>td {
		    background-color: #171B1E !important;
		}
		.el-table__empty-block {
		    background-color: #171B1E;
		}
	}
	.buttons{
		outline-style: none;
		border: none;
		border-radius: 4px;
		font-family: PingFang SC;
		color: #ffffff;
		position: relative;
		background-color: #307B37;
		cursor: pointer;
	}
	/deep/.paginatss {
		margin-top: .3rem;
	  display: flex;
	  .total{
	    width: auto;
	    height: .42rem !important;
	    line-height: .42rem;
	    padding: 0px .15rem;
	    margin-top: 2px;
	    box-sizing: border-box;
	    background: #171B1E;
	    border: 1px solid #464748;
	    border-radius: 2px;
	    font-size: .18rem;
	    font-family: PingFang SC;
	    font-weight: 500;
	    color: #B2B2B2;
	  }
	  .el-pagination span{
	    width: auto;
	    padding: .06rem .05rem;
	    line-height: .28rem;
	  }
	}
	/deep/ .searchlist .searchtime .el-input__inner{
		background-color: #171B1E;
	}
	/deep/ .searchlist .searchtime .el-input__inner .el-range-input{
		background-color: #171B1E;
	}
</style>
