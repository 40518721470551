<template>
  <div class="contents">
    <!-- <div class="header">
      <span></span>
      <span>{{ $t("lang.PackageCenter") }}</span>
      
    </div> -->
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      v-if="activetypes != 1"
    >
      <el-tab-pane :label="$t('lang.Packagemall')" name="first">
        <div class="shopll">
          <div
            class="goodsList"
            v-for="(item, index) in sllList.content"
            :key="index"
          >
            <div
              class="goodsimg"
              
            >
            <img class="imggdds" :src="item.logo" alt="">
            </div>
            <!--
              :style="'background: url(' + item.logo + ') no-repeat;'"
              <el-image
      style="width: 100%; height: 2rem"
      :src="item.logo"
      :fit="['cover']"></el-image> -->
            <div class="bordergoods">
              <div class="goodsname">{{ item.name }}</div>
              <div class="prices">
                <div class="priceleft">
                  {{ item.chargingUnit }}{{ item.allPrice }}
                </div>
                <div class="btnsprice" @click="todetails(item)">
                  {{ $t("lang.Buynow") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.Mypackage')" name="second">
        <div class="searchlist">
          <div class="searchtime" style="margin-right: 0.4rem">
            <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
            <el-date-picker
              v-model="value1"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.Startdate')"
              :end-placeholder="$t('lang.Enddate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="times"
            >
            </el-date-picker>
          </div>
          <div class="query">
            <div class="inputname">
              <input
                v-model="parsm.keyword"
                :placeholder="$t('lang.addnames')"
              />
            </div>
            <div class="searchbtn">
              <button @click="search">{{ $t("lang.query") }}</button>
            </div>
          </div>
        </div>
        <div class="tabelss">
          <el-table :data="tableData.content" style="width: 100%">
            <template slot="empty">
              <div class="noCart">{{ $t("lang.tableNull") }}</div>
            </template>
            <el-table-column
              prop="mealName"
              :label="$t('lang.Packagename')"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startTime"
              :label="$t('lang.Startdate')"
              align="center"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="endTime"
              :label="$t('lang.Enddate')"
              align="center"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="unit"
              :label="$t('lang.Months')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              :label="$t('lang.Timeofpurchase')"
              align="center"
              width="200"
            >
            </el-table-column>
            
            <el-table-column
              prop="unitPrice"
              :label="$t('lang.Costunitprice')"
              align="center"
              width="120"
              show-overflow-tooltip
            >
              <template #default="scope">
                <span
                  >{{ scope.row.chargingUnit }}{{ scope.row.unitPrice }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="discountPrice"
              :label="$t('lang.Discountfee')"
              align="center"
              width="130"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="flowQuota"
              :label="$t('lang.Flowlimit')"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="storageQuota"
              :label="$t('lang.storagequota')"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :label="$t('lang.Excesstrafficstrategy')"
              align="center"
              width="250"
            >
              <template #default="scope">
                <span v-if="scope.row.flowStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.flowStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="flowAdditionalUnit"
              :label="$t('lang.danjiafujia')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.flowAdditionalUnit">{{
                  scope.row.flowAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('lang.cucunxiane')"
              align="center"
              width="260"
            >
              <template #default="scope">
                <span v-if="scope.row.storageStrategy == 1">{{
                  $t("lang.Additionalbilling")
                }}</span>
                <span v-if="scope.row.storageStrategy == 0">{{
                  $t("lang.OutofService")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="storageAdditionalUnit"
              :label="$t('lang.fujiacucen')"
              align="center"
              width="270"
            >
              <template #default="scope">
                <span v-if="scope.row.storageAdditionalUnit">{{
                  scope.row.storageAdditionalUnit
                }}</span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="allPrice"
              :label="$t('lang.paymentamount')"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div
            style="text-align: right"
            class="paginatss"
            v-if="tableData.content && tableData.content.length > 0"
          >
            <div class="total">
              <span>{{ $t("lang.Total") }}</span> {{ tableData.totalElements }}
              <span>{{ $t("lang.Strip") }}</span>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="tableData.totalElements"
              @current-change="handleChange"
              :prev-text="$t('lang.previouspage')"
              :next-text="$t('lang.nextpage')"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <plantobuydetail
      :itemsobg="itemsobg"
      v-if="activetypes == 1"
      @cheactive="cheactive"
    ></plantobuydetail>
  </div>
</template>

<script>
import * as order from "@/api/order";
import * as contract from "@/api/contract.js";
import plantobuydetail from "./Plantobuydetail";
export default {
  components: {
    plantobuydetail,
  },
  data() {
    return {
      activeName: "first",
      activetypes: 0,
      parsm: {
        page: 1,
        size: 10,
        keyword: "",
        stime: "",
        etime: "",
        buyStime: "",
        buyEtime: "",
      },
      value1: "",
      tableData: "",
      sllList: {},
      parmes: {
        keyword: "",
        page: 1,
        size: 10,
      },
      itemsobg: {},
    };
  },
  created() {
    this.list();
    this.packgeLIsts();
  },
  filters: {
    monsa(val) {
      if (val) {
        return val.toFixed(2);
      } else {
        return val;
      }
    },
  },
  methods: {
    cheactive(active) {
      this.activetypes = active.id;
      this.activeName = "second"
      this.list();
      this.packgeLIsts();
    },
    todetails(item) {
      this.activetypes = 1;
      this.itemsobg = item;
    },
    //   获取套餐列表
    async packgeLIsts() {
      let res = await contract.mealRecordlist(this.parmes);
      console.log(res);
      this.sllList = res.data;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //获取订单列表
    async list() {
      let res = await contract.userMeallist(this.parsm);
      console.log(res);
      this.tableData = res.data;
    },
    // //订单状态筛选
    // orderstat(e) {
    //   this.parsm.page = 1;
    //   this.parsm.size = 10;
    //   this.list();
    // },
    //时间筛选
    times(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.buyStime = e[0];
        this.parsm.buyEtime = e[1];
      } else {
        this.parsm.buyStime = "";
        this.parsm.buyEtime = "";
      }
      this.list();
    },
    //订单编号筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.list();
    },
    //分页页数改变
    handleChange(page) {
      this.parsm.page = page;
      this.list();
    },
    //取消订单
    // guide(row) {
    //   this.$confirm(this.$t("lang.operationwill"), this.$t("lang.Tips"), {
    //     confirmButtonText: this.$t("lang.determine"),
    //     cancelButtonText: this.$t("lang.cancel"),
    //     type: "warning",
    //   }).then(() => {
    //     order.delelist(row.sn).then((res) => {
    //       let sk = res.msg;
    //       if (res.msg) {
    //         if (
    //           sessionStorage.getItem("language") &&
    //           sessionStorage.getItem("language") == "zhong"
    //         ) {
    //           let wq = sk.split("-");
    //           sk = wq[0];
    //         } else {
    //           let wq = sk.split("-");
    //           sk = wq[1];
    //         }
    //         this.$message.success(sk);
    //       }
    //       this.list();
    //     });
    //   });
    // },
    // 判断当前页是否是最后一页
    isLastPage() {
      const lastPage = Math.ceil(
        (this.tableData.totalElements - 1) / this.parsm.size
      );
      if (this.parsm.page === lastPage || this.parsm.page > lastPage) {
        this.parsm.page = lastPage;
      }
    },
    //去支付
    // invest(row) {
    //   this.$router.push({
    //     path: "/nav/personal",
    //     query: { ind: 5, id: row.sn },
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  color: #c2c2c2;
}
/deep/.el-tabs__item.is-active {
  color: #438749;
}
/deep/.el-tabs__item:hover {
  color: #438749;
}
/deep/.el-tabs__active-bar {
  background: #438749;
}
.contents {
  padding: 0.35rem 0.45rem;
  box-sizing: border-box;
  .header {
    font-size: 0.16rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #307b37;
    span:first-child {
      display: inline-block;
      width: 0.04rem;
      height: 0.2rem;
      background-color: #307b37;
      border-radius: 2px;
      margin-right: 0.07rem;
    }
  }
  .searchlist {
    margin-top: 0.28rem;
    font-size: 0.16rem;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    color: #c2c2c2;
    align-items: center;
    margin-bottom: 0.28rem;
  }
}
/deep/.tabelss {
  .el-table {
    border: 1px solid #9a9a9a;
    .has-gutter tr th {
      background-color: #222628;
      color: #c2c2c2;
      border-color: #9a9a9a;
    }
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-color: #9a9a9a;
  }
  .el-table::before {
    display: none;
  }
  .el-table tbody tr {
    background: #171b1e;
    width: 100%;
    font-size: 0.14rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #c2c2c2;
    td {
      border-color: #9a9a9a;
    }
  }
  .el-table tbody tr:last-child {
    td {
      border-bottom: none;
    }
  }
  .el-table tbody tr:hover > td {
    background-color: #171b1e !important;
  }
  .el-table__empty-block {
    background-color: #171b1e;
  }
}
.buttonsa {
  outline-style: none;
  border: 1px solid #307b37;
  border-radius: 4px;
  background-color: #171b1e;
  font-family: PingFang SC;
  color: #307b37;
  position: relative;
  cursor: pointer;
}
.buttons {
  outline-style: none;
  border: none;
  border-radius: 4px;
  font-family: PingFang SC;
  color: #ffffff;
  position: relative;
  background-color: #307b37;
  cursor: pointer;
}
/deep/.paginatss {
  margin-top: 0.3rem;
  display: flex;
  .total {
    width: auto;
    height: 0.42rem !important;
    line-height: 0.42rem;
    padding: 0px 0.15rem;
    margin-top: 2px;
    box-sizing: border-box;
    background: #171b1e;
    border: 1px solid #464748;
    border-radius: 2px;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
  }
  .el-pagination span {
    width: auto;
    padding: 0.06rem 0.05rem;
    line-height: 0.28rem;
  }
}
/deep/ .query .inputname input {
  background-color: #171b1e;
}
/deep/ .searchlist .searchtime .el-input__inner {
  background-color: #171b1e;
}
/deep/ .searchlist .searchtime .el-input__inner .el-range-input {
  background-color: #171b1e;
}
.shopll {
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #b2b2b2;
  .goodsList {
    width: 19%;
    margin-bottom: 0.25rem;
    margin-right: 0.15rem;
    .goodsimg {
      width: 100%;
      height: 2rem;
      background-position: center center;
      background-size: 100% auto;
      .imggdds{
         width: 100%;
         max-height: 2rem;
      }
    }
    .bordergoods {
      width: 100%;
      background: #1f2224;
      padding: 0.04rem 0.08rem;
      box-sizing: border-box;
      // border-left: 1px solid #b2b2b2;
      // border-right: 1px solid #b2b2b2;
      // border-bottom: 1px solid #b2b2b2;
      .goodsname {
        width: 100%;
        font-size: 0.2rem;
        padding: 0.1rem 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .prices {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .priceleft {
          font-size: 0.18rem;
          color: #fb6540;
        }
        .btnsprice {
          display: inline-block;
          padding: 0.08rem 0.18rem;
          border-radius: 2px;
          border: 1px solid #307b37;
          color: #307b37;
          font-size: 0.14rem;
          cursor: pointer;
        }
        .btnsprice:hover {
          background: #307b37;
          color: #ffffff;
        }
      }
    }
  }
}
// .shopll::after {
//   content: "";
//   width: 8.6rem;
// }
</style>
