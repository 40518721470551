<template>
	<div class="contents">
		<div class="header">
			<span></span>
			<span>{{$t('lang.billingcenters')}}</span>
			<span v-if="parsm.timeType==0" style="display: inline-block;font-size: .12rem;margin-left: .2rem;color: #C2C2C2;font-weight: 500;">{{$t('lang.onlythedetails')}}</span>
		</div>
		<div class="searchlist">
			<div class="select_bss" style="margin-right: .4rem">
			  <span style="color: #bbbbbb">{{$t('lang.billingcycle')}}：</span>
			  <el-select style="height: .4rem; width: 1.3rem" v-model="parsm.timeType" :placeholder="$t('lang.Please')" popper-class="select_ass" @change="orderstat">
			    <el-option :label="$t('lang.Querybymonth')" :value="0"></el-option>
			    <el-option :label="$t('lang.Querybyyear')" :value="1"></el-option>
			  </el-select>
			</div>
			<div class="select_bss" style="margin-right: .4rem">
			  <span style="color: #bbbbbb">{{$t('lang.Thebilltype')}}：</span>
			  <el-select style="height: .4rem; width: 1.3rem" v-model="parsm.type" :placeholder="$t('lang.Please')" popper-class="select_ass" @change="ordertype">
			    <el-option :label="$t('lang.whole')" value=""></el-option>
			    <el-option :label="$t('lang.Flowbill')" :value="0"></el-option>
			    <el-option :label="$t('lang.Storebills')" :value="1"></el-option>
			  </el-select>
			</div>
		</div>
		<div class="tabelss">
			<el-table :data="tableData.content" style="width: 100%">
				<el-table-column prop="period" align="center" :label="$t('lang.paymentdays')" width="120">
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.accountnumber')" width="180">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.consumptiontime')" width="350">
					<template slot-scope="scope">
					  <span>{{scope.row.startTime}}——</span>
					  <span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Thebilltype')">
					<template slot-scope="scope">
					  <span v-if="scope.row.type==1">{{$t('lang.Storebills')}}</span>
					  <span v-else>{{$t('lang.Flowbill')}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Amountpayable')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.money |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.paymentamount')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.payPrice |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Owetheamount')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.arrearsPrice |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('lang.operation')" align="center" width="200">
					<template slot-scope="scope">
						<el-button class="buttons" type="primary" size="small" @click='guide(scope.row)'>{{$t('lang.details')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right" class="paginatss" v-if="tableData.content && tableData.content.length > 0">
			  <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.totalElements}} <span>{{$t("lang.Strip")}}</span></div>
			  <el-pagination background layout="prev, pager, next" :total="tableData.totalElements" @current-change="handleChange"
			    :prev-text="$t('lang.previouspage')"
			    :next-text="$t('lang.nextpage')">
			  </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import * as bill from "@/api/order";
	export default{
		data() {
			return{
				parsm:{
					page:1,
					size:10,
					timeType:0,
					type:'',
					email:''
				},
				tableData:'',
			}
		},
		created() {
			this.list()
		},
		filters: {
			monsa(val){
				if(val){
					return val.toFixed(2)
				}else{
					return val
				}
			}
		},
		methods:{
			//获取总账单列表
			async list(){
				let res=await bill.billlist(this.parsm)
				this.tableData=res.data
			},
			//按年、按月筛选
			orderstat(){
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//账单类型筛选
			ordertype(){
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//分页页数改变
			handleChange(page){
				this.parsm.page=page
				this.list()
			},
			//详情
			guide(row){
				this.$router.push({
					path:'/nav/personal',
					query:{ind:2,inds:0,time:row.period,type:row.type}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.contents{
		padding: .35rem .45rem;
		box-sizing: border-box;
		.header{
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
		.searchlist{
			margin-top: .28rem;
			font-size: .16rem;
			display:flex;
			flex-flow: row;
			justify-content: flex-start;
			color: #c2c2c2;
			align-items: center;
			margin-bottom: .28rem;
		}
	}
	/deep/.tabelss{
		.el-table{
			border: 1px solid #9A9A9A;
			.has-gutter tr th{
				background-color: #222628;
				color: #c2c2c2;
				border-color: #9A9A9A;
			}
		}
		.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
			border-color: #9A9A9A;
		}
		.el-table::before{
			display: none;
		}
		.el-table tbody tr {
		    background: #171B1E;
		    width: 100%;
		    font-size: .14rem;
		    font-family: PingFang SC;
		    font-weight: 500;
		    color: #c2c2c2;
				td{
					border-color: #9A9A9A;
				}
		}
		.el-table tbody tr:last-child{
			td{
				border-bottom: none;
			}
		}
		.el-table tbody tr:hover>td {
		    background-color: #171B1E !important;
		}
		.el-table__empty-block {
		    background-color: #171B1E;
		}
	}
	.buttons{
		outline-style: none;
		border: none;
		border-radius: 4px;
		font-family: PingFang SC;
		color: #ffffff;
		position: relative;
		background-color: #1061C5;
		cursor: pointer;
	}
	/deep/.paginatss {
		margin-top: .3rem;
	  display: flex;
	  .total{
	    width: auto;
	    height: .42rem !important;
	    line-height: .42rem;
	    padding: 0px .15rem;
	    margin-top: 2px;
	    box-sizing: border-box;
			background: #171B1E;
	    border: 1px solid #464748;
	    border-radius: 2px;
	    font-size: .18rem;
	    font-family: PingFang SC;
	    font-weight: 500;
	    color: #B2B2B2;
	  }
	  .el-pagination span{
	    width: auto;
	    padding: .06rem .05rem;
	    line-height: .28rem;
	  }
	}
</style>
