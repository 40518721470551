<template>
	<div class="contents">
		<div class="header">
			<span></span>
			<span>{{$t('lang.Ordercenters')}}</span>
			<!-- <span style="display: inline-block;font-size: .12rem;margin-left: .2rem;color: #C2C2C2;font-weight: 500;">{{$t('lang.thlisttime')}}</span> -->
		</div>
		<div class="searchlist">
		  <div class="select_bss" style="margin-right: .4rem">
		    <span style="color: #bbbbbb">{{$t('lang.orderstatus')}}：</span>
		    <el-select style="height: .4rem; width: 1.3rem" v-model="parsm.orderStatus" :placeholder="$t('lang.Please')" popper-class="select_ass" @change="orderstat">
		      <el-option :label="$t('lang.whole')" value=""></el-option>
		      <el-option :label="$t('lang.obligation')" value="WAIT_PAY"></el-option>
		      <el-option :label="$t('lang.lostefficacy')" value="INVALID"></el-option>
		      <el-option :label="$t('lang.Completed')" value="COMPLETE"></el-option>
		      <el-option :label="$t('lang.canceled')" value="CANCELLED"></el-option>
		    </el-select>
		  </div>
			<div class="searchtime" style="margin-right: .4rem">
			  <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
			  <el-date-picker v-model="value1" type="daterange" :range-separator="$t('lang.to')" :start-placeholder="$t('lang.Startdate')"
			    :end-placeholder="$t('lang.Enddate')" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
			    @change="times">
			  </el-date-picker>
			</div>
		  <div class="query">
		    <div class="inputname">
		      <input v-model="parsm.keyword" :placeholder="$t('lang.odertexts')"/>
		    </div>
		    <div class="searchbtn">
		      <button @click="search">{{$t('lang.query')}}</button>
		    </div>
		  </div>
		</div>
		<div class="tabelss">
			<el-table :data="tableData.content" style="width: 100%">
				<el-table-column prop="id" align="center" :label="$t('lang.oderId')">
				</el-table-column>
				<el-table-column prop="sn" align="center" :label="$t('lang.ordernumber')" width="200">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.ordertypes')" width="150">
					<template slot-scope="scope">
					  <span v-if="scope.row.orderType==0">{{$t('lang.Balancerecharges')}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.bankNumber')" width="200">
				</el-table-column>
				<el-table-column prop="createTime" align="center" :label="$t('lang.creationTime')" width="200">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.timeofpayment')" width="200">
					<template slot-scope="scope">
					  <span v-if="scope.row.payTime">{{scope.row.payTime}}</span>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.rechargeamount')" width="150">
					<template slot-scope="scope">
						<span>{{scope.row.amount |monsa}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="balance" align="center" :label="$t('lang.Accountsbalance')" width="150">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.patternofpayment')" width="200">
					<template slot-scope="scope">
					  <span v-if="scope.row.payType==0">{{$t('lang.Wechatpayment')}}</span>
					  <span v-if="scope.row.payType==1">{{$t('lang.Alipaypayment')}}</span>
						<span v-if="scope.row.payType==null">/</span>
					</template>
				</el-table-column>
				<el-table-column prop="id" align="center" :label="$t('lang.orderstatus')" width="150">
					<template slot-scope="scope">
					  <span v-if="scope.row.orderStatus=='WAIT_PAY'">{{$t('lang.obligation')}}</span>
					  <span v-if="scope.row.orderStatus=='INVALID'">{{$t('lang.lostefficacy')}}</span>
					  <span v-if="scope.row.orderStatus=='COMPLETE'">{{$t('lang.Completed')}}</span>
					  <span v-if="scope.row.orderStatus=='CANCELLED'">{{$t('lang.canceled')}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('lang.operation')" align="center" width="180">
					<template slot-scope="scope">
						<el-button class="buttonsa" v-if="scope.row.orderStatus=='WAIT_PAY'" size="small" @click='guide(scope.row)'>{{$t('lang.cancel')}}</el-button>
						<el-button class="buttons" v-if="scope.row.orderStatus=='WAIT_PAY'" size="small" @click='invest(scope.row)'>{{$t('lang.Topay')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right" class="paginatss" v-if="tableData.content && tableData.content.length > 0">
			  <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.totalElements}} <span>{{$t("lang.Strip")}}</span></div>
			  <el-pagination background layout="prev, pager, next" :total="tableData.totalElements" @current-change="handleChange"
			    :prev-text="$t('lang.previouspage')"
			    :next-text="$t('lang.nextpage')">
			  </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import * as order from "@/api/order";
	export default{
		data() {
			return{
				parsm:{
					page:1,
					size:10,
					keyword:'',
					stime:'',
					etime:'',
					orderStatus:''
				},
				value1:'',
				tableData:'',
			}
		},
		created() {
			this.list()
		},
		filters:{
			monsa(val){
				if(val){
					return val.toFixed(2)
				}else{
					return val
				}
			}
		},
		methods:{
			//获取订单列表
			async list(){
				let res= await order.getorderlist(this.parsm)
				//console.log(res.data)
				this.tableData=res.data
			},
			//订单状态筛选
			orderstat(e){
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//时间筛选
			times(e){
				this.parsm.page=1
				this.parsm.size=10
				if(e){
					this.parsm.stime=e[0]
					this.parsm.etime=e[1]
				}else{
					this.parsm.stime=''
					this.parsm.etime=''
				}
				this.list()
			},
			//订单编号筛选
			search(){
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//分页页数改变
			handleChange(page){
				this.parsm.page=page
				this.list()
			},
			//取消订单
			guide(row){
				this.$confirm(
					this.$t("lang.operationwill"),
					this.$t("lang.Tips"),
					{
						confirmButtonText: this.$t("lang.determine"),
						cancelButtonText: this.$t("lang.cancel"),
						type: "warning",
					}
				).then(() => {
					order.delelist(row.sn).then(res=>{
						let sk=res.msg
						if(res.msg){
							if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
								let wq=sk.split('-')
								sk=wq[0]
							}else{
								let wq=sk.split('-')
								sk=wq[1]
							}
							this.$message.success(sk);
						}
						this.list()
					})
				});
			},
			// 判断当前页是否是最后一页
			isLastPage(){
				const lastPage = Math.ceil((this.tableData.totalElements - 1) / this.parsm.size)
				if (this.parsm.page === lastPage || this.parsm.page > lastPage) {
					this.parsm.page = lastPage
				}
			},
			//去支付
			invest(row){
				this.$router.push({
					path:'/nav/personal',
					query:{ind:5,id:row.sn}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.contents{
		padding: .35rem .45rem;
		box-sizing: border-box;
		.header{
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
		.searchlist{
			margin-top: .28rem;
			font-size: .16rem;
			display:flex;
			flex-flow: row;
			justify-content: flex-start;
			color: #c2c2c2;
			align-items: center;
			margin-bottom: .28rem;
		}
	}
	/deep/.tabelss{
		.el-table{
			border: 1px solid #9A9A9A;
			.has-gutter tr th{
				background-color: #222628;
				color: #c2c2c2;
				border-color: #9A9A9A;
			}
		}
		.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
			border-color: #9A9A9A;
		}
		.el-table::before{
			display: none;
		}
		.el-table tbody tr {
		    background: #171B1E;
		    width: 100%;
		    font-size: .14rem;
		    font-family: PingFang SC;
		    font-weight: 500;
		    color: #c2c2c2;
				td{
					border-color: #9A9A9A;
				}
		}
		.el-table tbody tr:last-child{
			td{
				border-bottom: none;
			}
		}
		.el-table tbody tr:hover>td {
		    background-color: #171B1E !important;
		}
		.el-table__empty-block {
		    background-color: #171B1E;
		}
	}
	.buttonsa{
		outline-style: none;
		border: 1px solid #307B37;
		border-radius: 4px;
		background-color: #171B1E;
		font-family: PingFang SC;
		color: #307B37;
		position: relative;
		cursor: pointer;
	}
	.buttons{
		outline-style: none;
		border: none;
		border-radius: 4px;
		font-family: PingFang SC;
		color: #ffffff;
		position: relative;
		background-color: #307B37;
		cursor: pointer;
	}
	/deep/.paginatss {
		margin-top: .3rem;
	  display: flex;
	  .total{
	    width: auto;
	    height: .42rem !important;
	    line-height: .42rem;
	    padding: 0px .15rem;
	    margin-top: 2px;
	    box-sizing: border-box;
	    background: #171B1E;
	    border: 1px solid #464748;
	    border-radius: 2px;
	    font-size: .18rem;
	    font-family: PingFang SC;
	    font-weight: 500;
	    color: #B2B2B2;
	  }
	  .el-pagination span{
	    width: auto;
	    padding: .06rem .05rem;
	    line-height: .28rem;
	  }
	}
	/deep/ .query .inputname input{
		background-color: #171B1E;
	}
	/deep/ .searchlist .searchtime .el-input__inner{
		background-color: #171B1E;
	}
	/deep/ .searchlist .searchtime .el-input__inner .el-range-input{
		background-color: #171B1E;
	}
</style>
