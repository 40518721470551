<template>
	<div class="contenst">
		<div class="header">
			<span></span>
			<span>{{ $t("lang.accountsettings") }}</span>
		</div>
		<div class="usermsgaa">
			<el-form :model="upFrom" :rules="rules" ref="ruleForm">
				<div class="picture">
				  <span style="display: inline-block;width:112px;">{{$t('lang.headportrait')}}：</span>
				  <div class="format">
				    <el-upload
					 class="avatar-uploader"
				      :action="uploadUrl"
				      :headers="headers"
				      :show-file-list="false"
				      :on-success="handleAvatarSuccess"
				      :before-upload="beforeAvatarUpload"
					  :file-list="fileList"
				    >
				      <div class="avatar-box">
				        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
				        <div class="upload-icon" v-if="imageUrl !=='@/assets/imgs/mrtx.png'">
				          <img src="../../../../assets/imgs/sahncghuan.png" alt="" style="width:.3rem;height:.2rem">
				          <!-- <div style="font-size:12px;color:#fff;padding-top:5px">{{$t('lang.Uploadnewimage')}}</div> -->
				        </div>
				      </div>
				    </el-upload>
				  </div>
				</div>
				<div class="userinputs">
				  <el-form-item prop="name" :label="$t('lang.Nickname')+'：'" label-width="100px">
				    <el-input v-model="upFrom.name" class="elint" :placeholder="$t('lang.Pleaseenteranickname')" style="width: 3rem;"></el-input>
				  </el-form-item>
				  <el-form-item prop="phone" :label="$t('lang.cellphonenumber')+'：'" label-width="100px" v-show="filg">
				    <el-input
				      v-model="upFrom.phone"
				       class="elint"
				      :placeholder="$t('lang.Pleaseinputmobilephonenumber')" @input='input' style="width: 3rem;"
				    ></el-input>
				  </el-form-item>
				  <el-form-item prop="code" :label="$t('lang.VerificationCode')+'：'" v-show="mobileCode" label-width="100px" class="codesa">
				    <el-input v-model="upFrom.code" style="width: 3rem;"></el-input>
				    <div class="send" @click="sendmsg">
				      <span v-show="msgtime">{{$t('lang.Sendverificationcode')}}</span>
				      <span v-show="!msgtime">{{ count }}S</span>
				    </div>
				  </el-form-item>
				</div>
			</el-form>
			<div class="btn">
				<button @click="removeset">{{$t('lang.cancel')}}</button>
			  <button @click="keepuser(upFrom)">{{$t('lang.preservation')}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import * as user from "@/api/apis";
	import { upload } from '@/utils/request'
	import { isMobile } from '@/utils/time'
	import * as RegExp from "@/ui-utils/RegExp.js";
	import {isemail } from '@/utils/time'
	export default{
		data() {
			var phone = (rule, value, callback) => {
			  var reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
			  if (!value) {
			    callback(new Error(this.$t('lang.Pleaseinputmobilephonenumber')));
			  } else {
			    callback();
			  }
			};
			var names = (rule, value, callback) => {
			  if (!value) {
			    callback(new Error(this.$t('lang.Pleaseenteranickname')));
			  } else {
			    callback();
			  }
			};
			var codes = (rule, value, callback) => {
			  if (!value) {
			    callback(new Error(this.$t('lang.Pleaseentertheverificationcode')));
			  } else {
			    callback();
			  }
			};
			return{
				imageUrl: require("@/assets/imgs/mrtx.png"),
				upFrom:{
					name:'',
					phone:'',
					code:'',
					email:''
				},
				rules: {
					name: [
						{
							required: true,
							//message: this.$t('lang.Pleaseenteranickname'),
							validator: names,
							trigger: "blur",
						},
					],
					phone: [
						{
							required: true,
							validator: phone,
							trigger: "blur",
						},
					],
					code: [
						{
							required: true,
							//message: this.$t('lang.Pleaseentertheverificationcode'),
							validator: codes,
							trigger: "blur",
						},
					],
				},
				uploadUrl:upload,
				headers:{
				  Authorization: JSON.parse(sessionStorage.getItem('token'))
				},
				msgtime: true,
				count: "",
				timer: null,
				imgurl: "",
				mobile: "",
				mobileCode: false,
				fileList:[],
				userdata:"",
				filg:true
			}
		},
		created() {
		  this.getUserInfo()
		},
		methods:{
			//获取用户信息
			getUserInfo(){
				this.userdata=JSON.parse(sessionStorage.getItem("user"))
				//console.log(sessionStorage.getItem("user"))
				if(!isemail(this.userdata.email)){
					this.filg=false
				}
				user.userId({ email: this.userdata.email }).then(res => {
					//console.log(res)
					this.upFrom.name=res.data.name
					this.upFrom.phone=res.data.phone
					this.upFrom.email=res.data.email
					this.imageUrl = res.data.img ? res.data.img : require("@/assets/imgs/mrtx.png");
				});
				this.mobile = this.userdata.mobile;
			},
			// 点击取消
			removeset(){
			  this.$router.go(-1)
			},
			//修改手机号码
			input(e){
				this.mobileCode=true
				if(e==''){
				  this.mobileCode=false
				}
			},
			//上传成功
			handleAvatarSuccess(res, file) {
			  this.imageUrl = URL.createObjectURL(file.raw);
			  this.imgurl = file.response.data;
			},
			//上传之前
			beforeAvatarUpload(file) {
			  let isIMG=file.name.split('.')[file.name.split('.').length - 1] === 'jpg' || file.name.split('.')[file.name.split('.').length - 1] === 'jpeg' || file.name.split('.')[file.name.split('.').length - 1] === 'png'
			  const isLt2M = file.size / 1024 / 1024 < 2;
			  if (!isIMG) {
			    this.$message.error(this.$t('lang.Uploadavatarimagecanonlybe')+'JPG、PNG、JPEG'+this.$t('lang.format'));
			  }
			  if (!isLt2M) {
			    this.$message.error(this.$t('lang.Thenumberofpicturesuploadedcannotexceed')+"2MB!");
			  }
			  return isIMG && isLt2M;
			},
			//提交
			keepuser(item) {
				if(!item.name){
					this.$message.error(this.$t("lang.Pleaseenteranickname"));
					return false;
				}
				if(item.phone&&this.userdata.phone!=item.phone&&!item.code){
					this.$message.error(this.$t("lang.Pleaseentertheverificationcode"));
					return false;
				}
			  let modifymsg = {
				  code: item.code,
				  email: item.email,
				  name: item.name,
				  phone:'',
				  img:''
			  };
				if(item.phone&&this.userdata.phone!=item.phone){
					modifymsg.phone= item.phone
				}
				if(this.imgurl.path){
					modifymsg.img=this.imgurl.path
				}
			  user.userUpdate(modifymsg).then((res) => {
					this.$message.success(this.$t("lang.Submitted"))
					this.getUserInfo()
					this.$router.go(-1)
			  });
			},
			//发送验证码
			sendmsg() {
				if(!isMobile(this.upFrom.phone)){
					this.$message.error(this.$t("lang.Pleaseinputthecorrectmobilephonenumber"));
					return false
				}
			  if (this.upFrom.phone && !this.timer) {
					console.log(this.upFrom.phone)
			    let sendval = {
			      phone: this.upFrom.phone,
						code:3
			    };
			    user.sendPhoneCode(sendval).then((res) => {
						let sk=res.msg
						if(res.msg){
							let wq=sk.split('-')
							if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
								sk=wq[0]
							}else{
								sk=wq[1]
							}
							this.$message.success(sk);
							if(wq[0].indexOf('操作过于频繁')!=0){
								this.getCode();
							}
						}
			    });
			  }
			},
			//倒计时
			getCode() {
			  const TIME_COUNT = 60;
			  if (!this.timer) {
			    this.count = TIME_COUNT;
			    this.msgtime = false;
			    this.timer = setInterval(() => {
			      if (this.count > 0 && this.count <= TIME_COUNT) {
			        this.count--;
			      } else {
			        this.msgtime = true;
			        clearInterval(this.timer);
			        this.timer = null;
			      }
			    }, 1000);
			  }
			}
		}
	}
</script>

<style lang="less" scoped>
	.contenst{
		padding: .15rem;
		box-sizing: border-box;
		.header{
			padding-top: .2rem;
			padding-left: .4rem;
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
		.usermsgaa{
			padding: .3rem 0 0 .4rem;
			width: 5.8rem;
			.picture{
				font-size: .16rem;
				display: flex;
				color: #C2C2C2;
				align-items: center;
				margin-bottom: .15rem;
				.format {
				  width: 3.7rem;
				  display: flex;
				  flex-direction: column;
				  span {
				    font-size: .14rem;
				    font-family: PingFang SC;
				    font-weight: 500;
				    color: #999999;
				    margin-bottom: .23rem;
				  }
				}
				img {
				  width: .8rem;
				  height: .8rem;
				  //background: #0a0f30;
				  opacity: 0.62;
				  border-radius: 50%;
				}
				p {
				  padding-left: 4px;
				  margin-right: .56rem;
				}
			}
			.btn {
			  display: flex;
			  align-content: center;
			  justify-content: flex-start;
			  padding-left: 1rem;
			  margin-top: .5rem;
			  button {
			    width: .94rem;
			    height: .34rem;
			    outline-style: none;
			    border: none;
			    background: #307B37;
			    border-radius: 2px;
			    font-size: .14rem;
			    font-family: PingFang SC;
			    font-weight: 500;
			    color: #ffffff;
			    &:first-of-type {
						background-color: #1F2224;
						border: 1px solid #909090;
			      margin-right: .3rem;
			    }
			  }
			}
		}
		/* 验证码发送 */
		.send {
		  width: .9rem;
		  height: .3rem;
		  background: #307B37;
		  border-radius: 2px;
			display: flex;
			flex-flow: row;
			justify-content: center;
			align-items: center;
		  position: absolute;
		  cursor: pointer;
		  right: .1rem;
		  top: 50%;
			transform: translateY(-50%);
		  z-index: 10;
		  span {
				display: inline-block;
		    font-size: .14rem;
		    font-family: PingFang SC;
		    font-weight: 500;
		    color: #ffffff;
		  }
		}
		.avatar-box{
		  // background-color: #333;
		  position: relative;
		}
		.upload-icon{
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		}
	}
	/deep/.userinputs{
		.el-form-item {
			display: flex;
			.el-form-item__label{
				text-align: left;
				color: #C2C2C2;
				font-size: .16rem;
			}
			.el-form-item__label::before{
				display: none;
			}
			.el-form-item__content{
				margin-left: 0 !important;
				input{
					background-color: #171B1E;
					border-color: #909090;
					color: #C2C2C2 !important;
				}
			}
		}
	}
	/deep/ .userinputs{
		.el-input__inner{
			height: .5rem;
		}
	}
	/deep/ .codesa{
		.el-input__inner{
			padding: 0 1.2rem 0 .15rem;
		}
	}
</style>
