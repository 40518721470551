<template>
	<div class="contents">
		<div class="header">
			<span></span>
			<span v-if="billtype==1">{{$t('lang.Billdetails')}}</span>
			<span v-else>{{$t('lang.Billflow')}}</span>
		</div>
		<div class="headers" v-if="billtype==1"></div>
		<div class="searchlist" v-if="billtype!=1">
			<!-- <div class="queryss" v-if="billtype==1">
			  <div class="inputname">
			    <input v-model="parsms.keyword" :placeholder="$t('lang.Pleasebillnumber')"/>
			  </div>
			  <div class="searchbtn">
			    <button @click="search">{{$t('lang.query')}}</button>
			  </div>
			</div> -->
			<div class="select_bss" style="margin-right: .4rem">
			  <span style="color: #bbbbbb">{{$t('lang.Flowconsumption')}}：</span>
			  <el-select style="height: .4rem; width: 1.3rem" v-model="parsm.type" :placeholder="$t('lang.Please')" popper-class="select_ass" @change="orderstat">
			    <el-option :label="$t('lang.whole')" value=""></el-option>
			    <el-option :label="$t('lang.la')" value="1"></el-option>
			    <el-option :label="$t('lang.tui')" value="2"></el-option>
			    <el-option :label="$t('lang.pullzip')" value="3"></el-option>
			  </el-select>
			</div>
		</div>
		<div class="tabelss">
			<el-table :data="tableData.content" style="width: 100%" v-if="billtype==1">
				<el-table-column align="center" :label="$t('lang.paymentdays')">
					<template slot-scope="scope">
					  <span>{{scope.row.createTime | times}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.bankNumber')" width="180">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.time')" width="350">
					<template slot-scope="scope">
					  <span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="media" align="center" :label="$t('lang.Mediafiles')" width="250">
				</el-table-column>
				<el-table-column prop="mediaName" align="center" :label="$t('lang.fileNames')" width="250">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Changetype')">
					<template slot-scope="scope">
					  <span v-if="scope.row.increment==1">{{$t('lang.reduce')}}</span>
					  <span v-else>{{$t('lang.increase')}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Changedosage')">
					<template slot-scope="scope">
					  <span>{{scope.row.amount |amoutsa}}GB</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Totalstorages')">
					<template slot-scope="scope">
					  <span>{{scope.row.afterAmount |amoutsa}}GB</span>
					</template>
				</el-table-column>
			</el-table>
			<el-table :data="tableData.content" style="width: 100%" v-else>
				<el-table-column align="center" :label="$t('lang.paymentdays')">
					<template slot-scope="scope">
					  <span>{{scope.row.createTime | times}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.bankNumber')" width="180">
				</el-table-column>
				<el-table-column prop="sn" align="center" :label="$t('lang.billnumber')" width="180">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Thebilltype')">
					<template slot-scope="scope">
					  <span v-if="scope.row.type==0">{{$t('lang.Flowbill')}}</span>
					  <span v-else>{{$t('lang.Storebills')}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Flowconsumption')" width="200">
					<template slot-scope="scope">
					  <span v-if="scope.row.content==1">{{$t('lang.la')}}</span>
					  <span v-if="scope.row.content==2">{{$t('lang.tui')}}</span>
					  <span v-if="scope.row.content==3">{{$t('lang.pullzip')}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="media" align="center" :label="$t('lang.route')" width="300">
				</el-table-column>
				<el-table-column prop="ip" align="center" :label="$t('lang.targetIp')">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.fileSize')" width="200">
					<template slot-scope="scope">
					  <span v-if="scope.row.devMid">{{scope.row.devMid}}</span>
					  <span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="amount" align="center" :label="$t('lang.dosage')">
					<template slot-scope="scope">
					  <span>{{scope.row.amount |amoutsa}}GB</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('lang.balance')" width="100" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.money |monsa}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right" class="paginatss" v-if="tableData.content && tableData.content.length > 0">
			  <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.totalElements}} <span>{{$t("lang.Strip")}}</span></div>
			  <el-pagination background layout="prev, pager, next" :total="tableData.totalElements" @current-change="handleChange"
			    :prev-text="$t('lang.previouspage')"
			    :next-text="$t('lang.nextpage')">
			  </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import * as flow from "@/api/order";
	export default{
		data() {
			return{
				parsm:{
					page:1,
					size:10,
					type:'',
					sn:'',
					email:''
				},
				tableData:'',
				billtype:'',
				parsms:{
					page:1,
					size:10,
					etime:'',
					stime:'',
					keyword:'',
					email:''
				}
			}
		},
		created() {
			if(this.$route.query.type){
				this.parsm.sn=this.$route.query.sn
				this.billtype=this.$route.query.type
			}
			if(this.$route.query.etime){
				this.parsms.etime=this.$route.query.etime
				this.parsms.stime=this.$route.query.stime
			}
			if(this.billtype==1){
				this.lists()
			}else{
				this.list()
			}
		},
		filters:{
			times(val){
				let skr=''
				if(val){
					let sk=val.split(' ')
					let skk=sk[0].split('-')
					skr=skk[0]+'-'+skk[1]
				}
				return skr
			},
			amoutsa(val){
				if(val){
					return val.toFixed(4)
				}else{
					return val
				}
			},
			monsa(val){
				if(val){
					return val.toFixed(2)
				}else{
					return val
				}
			}
		},
		methods:{
			//获取流量账单明细列表
			async list(){
				let res=await flow.flowlist(this.parsm)
				console.log(res)
				this.tableData=res.data
			},
			//获取存储账单明细列表
			async lists(){
				let res=await flow.storagelist(this.parsms)
				console.log(res)
				this.tableData=res.data
			},
			//流量消耗类型筛选
			orderstat(e){
				//console.log(e)
				this.parsm.type=e
				this.parsm.page=1
				this.parsm.size=10
				this.list()
			},
			//分页页数改变
			handleChange(page){
				if(this.billtype==1){
					this.parsms.page=page
					this.lists()
				}else{
					this.parsm.page=page
					this.list()
				}
			},
			//搜索筛选
			search(){
				console.log('lkk')
				this.parsms.page=1
				this.lists()
			}
		}
	}
</script>

<style lang="less" scoped>
	.contents{
		padding: .35rem .45rem;
		box-sizing: border-box;
		.header{
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
		.headers{
			margin-bottom: 0.28rem;
		}
		.searchlist{
			margin-top: .28rem;
			font-size: .16rem;
			display:flex;
			flex-flow: row;
			justify-content: flex-start;
			color: #c2c2c2;
			align-items: center;
			margin-bottom: .28rem;
		}
	}
	/deep/.tabelss{
		.el-table{
			border: 1px solid #9A9A9A;
			.has-gutter tr th{
				background-color: #222628;
				color: #c2c2c2;
				border-color: #9A9A9A;
			}
		}
		.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
			border-color: #9A9A9A;
		}
		.el-table::before{
			display: none;
		}
		.el-table tbody tr {
		    background: #171B1E;
		    width: 100%;
		    font-size: .14rem;
		    font-family: PingFang SC;
		    font-weight: 500;
		    color: #c2c2c2;
				td{
					border-color: #9A9A9A;
				}
		}
		.el-table tbody tr:last-child{
			td{
				border-bottom: none;
			}
		}
		.el-table tbody tr:hover>td {
		    background-color: #171B1E !important;
		}
		.el-table__empty-block {
		    background-color: #171B1E;
		}
	}
	.buttons{
		outline-style: none;
		border: none;
		border-radius: 4px;
		font-family: PingFang SC;
		color: #ffffff;
		position: relative;
		background-color: #307B37;
		cursor: pointer;
	}
	/deep/.paginatss {
		margin-top: .3rem;
	  display: flex;
	  .total{
	    width: auto;
	    height: .42rem !important;
	    line-height: .42rem;
	    padding: 0px .15rem;
	    margin-top: 2px;
	    box-sizing: border-box;
	    background: #171B1E;
	    border: 1px solid #464748;
	    border-radius: 2px;
	    font-size: .18rem;
	    font-family: PingFang SC;
	    font-weight: 500;
	    color: #B2B2B2;
	  }
	  .el-pagination span{
	    width: auto;
	    padding: .06rem .05rem;
	    line-height: .28rem;
	  }
	}
</style>
