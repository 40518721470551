<template>
	<div class="contenst">
		<div class="topimg"></div>
		<div class="header">
			<div :class="daset ?'activs':''">
				<span>1.{{$t('lang.Checkaccount')}}</span>
				<span class="triangle"></span>
			</div>
			<div :class="daset_a ?'activs':''">
				<span>2.{{$t('lang.Confirmandpay')}}</span>
				<span class="triangle"></span>
			</div>
			<div :class="daset_b ?'activs':''">
				<span>3.{{$t('lang.complete')}}</span>
			</div>
		</div>
		<div class="conter" v-if="daset&&daset_c==1">
			<div style="margin-top: 0;">
				<label>{{$t('lang.Rechargeaccount')}}：</label>
				<span>{{user.email}}</span>
			</div>
			<div>
				<label>{{$t('lang.Currentbalance')}}：</label>
				<span>￥{{user.balance}}</span>
			</div>
			<div class="monyle">
				<label>{{$t('lang.Fastrecharge')}}：</label>
				<div class="monyle_a">
					<div v-for="(item,index) in mony" :key='index' :class="flig==index?'actives':''" @click="choice(index,item)">{{item}}RMB</div>
				</div>
			</div>
			<div class="monsl">
				<label>{{$t('lang.Otheramounts')}}：</label>
				<el-input v-model="input" :placeholder="$t('lang.Pleaseenterpositive')" :maxlength="4" @input='inpuss' style="width: 3.2rem;"></el-input>
				<span class="tips">{{$t('lang.Rechargerecord')}}
					<span style="color: #307B37;cursor: pointer;margin: 0 0.02rem;" @click="taps">{{$t('lang.Ordercenter')}}</span>
					{{$t('lang.see')}}
				</span>
			</div>
			<div style="margin-bottom: .3rem;">
				<label>{{$t('lang.paymentamount')}}：</label>
				<span style="color: #307B37;">￥{{input}}</span>
			</div>
			<div class="button" @click="invest">{{$t('lang.Rechargenows')}}</div>
		</div>
		<div v-if="daset_a&&daset_c==2" class="conter_a">
			<div style="margin-top: 0;color: #307B37;">
				<i class="el-icon-success"></i>
				<span style="font-size: .18rem;">{{$t('lang.Ordersubmitted')}}</span>
			</div>
			<div>
				<label>{{$t('lang.ordernumber')}}：</label>
				<span>{{orderdata.sn}}</span>
			</div>
			<div>
				<label>{{$t('lang.Rechargeaccount')}}：</label>
				<span>{{orderdata.email}}</span>
			</div>
			<div>
				<label>{{$t('lang.Amountpayable')}}：</label>
				<span style="color: #307B37;">￥{{orderdata.orderPrice}}</span>
			</div>
			<div>
				<span style="font-size: .15rem;">{{$t('lang.Pleasecompletepayment')}}</span>
			</div>
			<div>
				<label>{{$t('lang.patternofpayment')}}：</label>
				<div class="depost">
					<el-radio v-model="radio" label="1" @change='deposit'>
						<div class="depos">
							<img style="width: .98rem;height: .34rem;" src="@/assets/imgs/devlis/zfb.png" />
						</div>
					</el-radio>
					<el-radio v-model="radio" label="2" @change='deposit'>
						<div class="depos">
							<img style="width: 1.22rem;height: .33rem;" src="@/assets/imgs/devlis/wx.png" />
						</div>
					</el-radio>
				</div>
			</div>
			<div v-if="filgwx">
				<div class="code">
					<div class="code_a">
						<img :src='wximg' />
					</div>
					<div style="display: flex;align-items: center;">
						<img style="width: .36rem;height: .29rem;margin-right: .1rem;" src="@/assets/imgs/devlis/weixin.png" />
						<span>{{$t('lang.Paywechatcode')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="daset_b&&daset_c==3" class="conter_b">
			<div class="cont">
				<div class="cont_a">
					<i class="el-icon-success"></i>
					<div>
						<div style="color: #307B37;font-size: .3rem;line-height: .5rem;">{{$t('lang.Congratulations')}}</div>
						<div style="margin-top: .15rem;" class="cont_b">
							<label>{{$t('lang.Rechargetime')}}：</label>
							<span>{{orderdata.payTime}}</span>
						</div>
						<div class="cont_b">
							<label>{{$t('lang.ordernumber')}}：</label>
							<span>{{orderdata.sn}}</span>
						</div>
						<div class="cont_b">
							<label>{{$t('lang.Rechargeaccount')}}：</label>
							<span>{{orderdata.email}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as order from "@/api/order";
	export default{
		data() {
			return{
				mony:['50','100','200','500','1000'],
				flig:'',
				input:50,
				daset:true,
				daset_a:false,
				daset_b:false,
				daset_c:1,
				user:'',
				orderid:'',
				radio:'',
				orderdata:'',
				filgwx:false,
				wximg:'',
				timell:null,
				wxpd:false
			}
		},
		watch:{
			$route: {
			  handler: function () {
				if(this.$route.query.ind&&!this.$route.query.id){
					Object.assign(this.$data, this.$options.data());
					this.user = JSON.parse(sessionStorage.getItem("user"))
				}
				if(this.$route.query.id){
					this.orderid=this.$route.query.id
					this.getdeit()
				}
			  },
			  // 深度观察监听
			  deep: true,
			},
		},
		created() {
			this.user = JSON.parse(sessionStorage.getItem("user"))
			if(this.$route.query.id){
				this.orderid=this.$route.query.id
				this.getdeit()
			}
		},
		methods:{
			//选择金额
			choice(index,row){
				this.flig=index
				this.input=row
			},
			inpuss(){
				this.flig=8
			},
			//订单中心
			taps(){
				this.$router.push({
					path:'/nav/personal',
					query:{ind:1}
				})
			},
			//立即充值
			async invest(){
				const trgse = /^([1-9]\d*|[-1]{1,1})$/
				console.log(trgse.test(this.input))
				if(!trgse.test(this.input)){
					this.$message.error(this.$t('lang.Pleaseenterpositives'))
					return false
				}
				if(this.input>1000){
					this.$message.error(this.$t('lang.Pleaseenterpositive'))
					return false
				}
				let data={
					money:this.input
				}
				let res=await order.addorder(data)
				let sk=res.msg
				if(res.msg){
					if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
						let wq=sk.split('-')
						sk=wq[0]
					}else{
						let wq=sk.split('-')
						sk=wq[1]
					}
					this.$message.success(sk);
				}
				if(res.data){
					this.daset_c=2
					this.daset_a=true
					//this.orderid=res.data
					this.$router.push({
						path:'/nav/personal',
						query:{ind:5,id:res.data}
					})
				}
			},
			//选择充值方式
			deposit(e){
				if(e==1){
					order.orderzfb(this.orderid).then(res=>{
						//console.log(res)
						if(res.data){
							if(res.data=='该订单已支付'){
								this.getdeit()
							}else{
								let divForm = document.getElementsByTagName('divform')
								if (divForm.length) {
									document.body.removeChild(divForm[0]);
								}
								const div=document.createElement('divform');
								div.innerHTML=res.data;
								document.body.appendChild(div);
								document.forms[0].setAttribute('target', '_blank');
								document.forms[0].submit();
							}
						}
					})
				}else{
					order.orderwx(this.orderid).then(res=>{
						if(res.data){
							if(res.data.data=='该订单已支付'){
								this.getdeit()
							}else{
								this.filgwx=true
								this.wximg=res.data.gateway_url
								this.timell=setInterval(()=>{
									this.judge()
									if(this.wxpd){
										clearInterval(this.timell)
										this.getdeit()
									}
								},3000)
							}
						}
					})
				}
			},
			//判断微信支付是否成功
			async judge(){
				let dase=await order.orderwxpd(this.orderid)
				//console.log(dase)
				if(dase.data==0){
					this.wxpd=true
				}
			},
			//获取订单详情
			async getdeit(){
				let res=await order.orderdet(this.orderid)
				//console.log(res)
				this.orderdata=res.data
				if(res.data.payStatus=='PAY_NO'){
					this.daset_c=2
					this.daset_a=true
				}else{
					this.daset_c=3
					this.daset_a=true
					this.daset_b=true
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.contenst{
		//padding-left: .2rem;
		height: 100%;
		.topimg{
			//height: 1.3rem;
			img{
				height: 1.3rem;
			}
		}
		.header{
			font-size: .16rem;
			display: flex;
			flex-flow: row;
			padding: .15rem 0 0 .32rem;
			box-sizing: border-box;
			div{
				width: 31%;
				text-align: center;
				padding: .1rem 0;
				background-color: #E4E4E4;
				color: #666666;
				position: relative;
				.triangle{
					content: "";
					border: 2px solid #1A2621;
					border-bottom: none;
					border-left: none;
					background-color: #E4E4E4;
					height:.28rem;
					width: .288rem;
					position: absolute;
					display: block;
					top: .058rem;
					right: -.17rem;
					z-index: 10;
					transform:rotate(45deg);
				}
			}
			.activs{
				background-color: #307B37;
				color: #fff;
				.triangle{
					background-color: #307B37;
				}
			}
		}
		.conter{
			padding-left: .9rem;
			margin-top: .4rem;
			font-size: .16rem;
			color: #C2C2C2;
			>div{
				margin-bottom: .2rem;
			}
			.monyle{
				display: flex;
				align-items: center;
				.monyle_a{
					display: flex;
					flex-flow: row;
					justify-content: flex-start;
					div{
						width: 1rem;
						height: .36rem;
						border: 1px solid #C2C2C2;
						line-height: .36rem;
						text-align: center;
						margin-right: .15rem;
						border-radius: 2px;
						cursor: pointer;
					}
					.actives{
						border: 1px solid #307B37;
						color: #307B37;
					}
				}
			}
			/deep/.monsl{
				.el-input__inner{
					background-color: #171B1E;
					border:1px solid #C2C2C2;
					color: #C2C2C2;
				}
			}
			.tips{
				font-size: .14rem;
				color: #7F7F7F;
				display: inline-block;
				margin-left: .2rem;
			}
			.button{
				width: 1.1rem;
				height: .4rem;
				text-align: center;
				line-height: .4rem;
				box-sizing: border-box;
				background-color: #307B37;
				font-size: .16rem;
				color: #FFFFFF;
				border-radius: 3px;
				margin-left: .8rem;
				cursor: pointer;
			}
		}
		.conter_a{
			padding-left: .65rem;
			margin-top: .35rem;
			color: #C2C2C2;
			>div{
				margin-bottom: .2rem;
				font-size: .16rem;
				display: flex;
				align-items: center;
				i{
					font-size: .2rem;
					margin-right: .05rem;
				}
			}
			.depost{
				display: flex;
				flex-flow: row;
				justify-content: flex-start;
				align-items: center;
				label{
					display: flex;
					align-items: center;
				}
				.depos{
					width: 1.4rem;
					height: .5rem;
					border: 1px solid #C2C2C2;
					border-radius: 2px;
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
				}
				/deep/ .el-radio__input.is-checked .el-radio__inner{
					border-color: #307B37;
					background: #307B37;
				}
				/deep/ .el-radio__input.is-checked+.el-radio__label{
					.depos{
						border: 1px solid #307B37;
					}
				}
			}
			.code{
				display: flex;
				flex-flow: column;
				align-items: center;
				padding-left: .8rem;
				margin-top: .25rem;
				.code_a{
					border: 1px solid #E4E4E4;
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					margin-bottom: .15rem;
				}
			}
			
		}
		.conter_b{
			padding: .3rem 0 0 .15rem;
			height: calc(100vh - 3rem);
			.cont{
				width: 8.9rem;
				height: 3.7rem;
				background-color: #171B1E;
				color: #C2C2C2;
				position: relative;
				z-index: 99;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
				font-size: .16rem;
				.cont_a{
					display: flex;
					i{
						color: #307B37;
						font-size: .5rem;
						margin-right: .25rem;
					}
				}
				.cont_b{
					margin-bottom: .05rem;
				}
			}
		}
	}
</style>
