<template>
	<div class="contenst">
		<div class="header">
			<span></span>
			<span>{{$t("lang.Streamingscheme")}}</span>
		</div>
		<div class="plan">
			<div class="box-title">
				<div v-for="(item,index) in tuils" :key='index' :class="filg==index?'active':''" @click="diaj(index)" class="box-titlea">
					<img :src="item.img" />
					<span>{{item.text}}</span>
				</div>
			</div>
			<el-button v-if="filg==0" size="small" icon="el-icon-plus" class="addbutton" @click="handleAddPlan">{{ $t("lang.AddScheme") }}</el-button>
			<div class="item-box" v-if="filg==0">
			  <div class="plan-item" v-for="(item,index) in planData" :key="index">
				<div class="plan-items">
					<el-form ref="planForm" :model="item">
					  <div class="planads" style="display: flex;">
							<el-form-item :label="$t('lang.SchemeNamessa')" class="planName-box">
								<el-input style="width:3.2rem" v-model="item.name" clearable></el-input>
							</el-form-item>
							<div class="del-icon" @click="handd(item)">
							  <img src="@/assets/imgs/devlis/xiug.png" alt="">
							</div>
						</div>
					  <div class="address-item" v-for="(obj,idx) in item.nameUrls" :key="idx">
						<el-form-item :label="$t('lang.streamingAddressaa')">
						  <el-input style="width:3.2rem" v-model="obj.url" clearable @blur="validateUrl(obj.url)" :maxlength="256"></el-input>
						</el-form-item>
						<div class="del-icon" @click="handleDelPlanAddress(index,idx,item.id)">
						  <img src="@/assets/imgs/devlis/sc.png" alt="">
						</div>
					  </div>
					</el-form>
					<el-button size="small" type="plain" class="addbuttons" icon="el-icon-plus" @click="handleAddAddress(index)">{{$t('lang.Addaddress')}}</el-button>
					<div class="bottom-btn">
						<el-button size="small" class="plan-btn" @click="delPlan(index,item.id)">{{$t('lang.DeleteScheme')}}</el-button>
					  <el-button size="small" class="plan-btn" @click="submitPlan(index)">{{$t('lang.SaveScheme')}}</el-button>
					</div>
				</div>
			  </div>
			</div>
			<div class="item-box" v-else>
			  <div class="plan-item">
					<div class="plan-items">
						<el-form :model="frequentPlan">
							<div class="address-item" v-for="(item,index) in frequentPlan.nameUrls" :key="index">
								<el-form-item :label="$t('lang.streamingAddressaa')">
								<el-input style="width:3.20rem" v-model="item.url" @blur="validateUrl(item.url)" :maxlength="256"></el-input>
								</el-form-item>
								<div class="del-icon" @click="handleDelFrequentAddress(index,item.id)">
								<img src="@/assets/imgs/devlis/sc.png" alt="">
								</div>
							</div>
						</el-form>
						<el-button size="small" type="plain" class="addbuttons" icon="el-icon-plus" @click="handleAddFrequentAddress">{{$t('lang.Addaddress')}}</el-button>
						<div class="bottom-btn">
							<el-button size="small" class="plan-btn" @click="submitFrequentAddress">{{$t('lang.SaveAddress')}}</el-button>
						</div>
					</div>
				</div>
			  </div>
		</div>
	</div>
</template>

<script>
	import * as plan from '@/api/apis';
	export default{
		data() {
			return{
				planData:[],
				frequentPlan:{
				  email: "",
				  name: "",
				  nameUrls: []
				},
				filg:0
			}
		},
		computed:{
			tuils(){
				return [
					{text:this.$t('lang.Streamingscheme'),img:require("@/assets/imgs/devlis/fagl.png")},
					{text:this.$t('lang.FrequentlyStreamAddress'),img:require("@/assets/imgs/devlis/tldz.png")}
				]
			}
		},
		created(){
		  this.getAllPlan()
			if(this.$route.query.type){
				this.filg=1
			}
		},
		methods:{
			//切换
			diaj(index){
				this.filg=index
			},
			//获取所有方案
			async getAllPlan(){
			  let email = JSON.parse(sessionStorage.getItem("user")).email;
			  let { data } = await plan.solList({email:email});
			  if(data !== null){
			    this.planData = data.filter(item => item.name !== null);
			    let frequentPlan = data.filter(item => item.name === null)
			    if(frequentPlan.length){
			      this.frequentPlan = frequentPlan[0]
			    }else{
			      this.frequentPlan = {
			        email: "",
			        name: "",
			        nameUrls: []
			      }
			    }
			  }else{
			    this.planData = [];
			    this.frequentPlan = {
			      email: "",
			      name: "",
			      nameUrls: []
			    }
			  }
			},
			//获取焦点
			validateUrl(val){
				let sk=/^[^\s]*$/
				if(!sk.test(val)){
					this.$message.error(this.$t("lang.Pleaseenterthecorrects"));
					return;
				}
				let urlRegex =
				/(udp|rtmp|srt|rtmps+):\/\/\b[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62}){3,10}\b(:(6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]|[0-5]?\d{0,4}))?([\/a-z,A-Z]*)/g;
			  if(val && !urlRegex.test(val)){
			    this.$message.error(this.$t('lang.Pleaseenter'));
			    return
			  }
			},
			//添加方案
			handleAddPlan(){
			  this.planData.unshift({
			    email: "",
			    name: "",
			    nameUrls: [
			        {
			          name: "",
			          url: ""
			        }
			    ]
			  })
			},
			//新增地址
			handleAddAddress(index){
			  if(this.planData[index].nameUrls.length < 4){
			    this.planData[index].nameUrls.push({name:"",url:""})
			  }else{
					this.$message.error(this.$t('lang.vbs'))
				}
			},
			//删除方案地址
			handleDelPlanAddress(index,idx,id){
			  if(this.planData[index].nameUrls.length !== 1){
			    this.planData[index].nameUrls.splice(idx,1)
			  }
			},
			//删除方案名称
			handd(row){
				row.name=''
			},
			// 保存方案
			submitPlan(index){
			  this.planData[index].email = JSON.parse(sessionStorage.getItem("user")).email;
			  let params = this.planData[index];
			  let canSubmit = true;
				let sk=/^[^\s]*$/
				let urlRegex = /^((https|http|ftp|rtsp|mms|rtp|artc|rtmp|RTMP|rtmp|RTMPS|rtmps|SRT|srt|ARTC|artc)?:\/\/|www\.)[^\s]+/;
			  for(let item of params.nameUrls){
					if(!sk.test(item.url)){
						canSubmit = false;
					}
			    if(!urlRegex.test(item.url)){
			      canSubmit = false;
			    }
			  }
			  if(canSubmit){
			    plan.solution(params).then(res => {
			      this.$message({
			        message: this.$t('lang.addsuccess'),
			        type: 'success'
			      });
			      this.getAllPlan()
			    })
			  }else{
			    this.$message.error(this.$t('lang.Pleaseenter'));
			    return
			  }
			  
			},
			// 删除方案
			delPlan(index,id){
			  if(!id){
			    this.planData.splice(index,1)
			  }else{
			    this.$confirm( this.$t('lang.ThisActionPermanentlyDeletesTheSchema') + " " + this.$t('lang.WhetherOrNotToContinue'), this.$t('lang.Tips'), {
			      confirmButtonText: this.$t('lang.determine'),
			      cancelButtonText: this.$t('lang.cancel'),
			      type: 'warning'
			    }).then(() => {
			      plan.deleteSol({id1:id,id2:""}).then(() => {
			        this.$message({
			          message: this.$t('lang.deletessuccess'),
			          type: 'success'
			        });
			        this.getAllPlan()
			      })
			    }).catch(() => {
			      this.$message({
			        type: 'info',
			        message: this.$t('lang.CanceledDelete')
			      });          
			    });
			  }
			},
			//添加常用地址
			handleAddFrequentAddress(){
			  if(this.frequentPlan.nameUrls.length < 4){
			    this.frequentPlan.nameUrls.push({name:"",url:""})
			  }else{
						this.$message.error(this.$t('lang.vbs'))
					}
			},
			//删除常用推流地址
			handleDelFrequentAddress(index,id){
			  if(!id){
			    this.frequentPlan.nameUrls.splice(index,1)
			  }else{
			    this.$confirm(this.$t('lang.ThisActionPermanentlyDeletesTheAddress') + " " + this.$t('lang.WhetherOrNotToContinue'), this.$t('lang.Tips'), {
			      confirmButtonText: this.$t('lang.determine'),
			      cancelButtonText: this.$t('lang.cancel'),
			      type: 'warning'
			    }).then(() => {
			      plan.deleteSol({id1:"",id2:id}).then(() => {
			        this.$message({
			          message:  this.$t('lang.deletessuccess'),
			          type: 'success'
			        });
			        this.getAllPlan()
			      })
			    }).catch(() => {
			      this.$message({
			        type: 'info',
			        message: this.$t('lang.CanceledDelete')
			      });          
			    });
			  }
			},
			// 保存常用推流地址
			submitFrequentAddress(){
			  this.frequentPlan.email = JSON.parse(sessionStorage.getItem("user")).email;
			  let canSubmit = true;
				let sk=/^[^\s]*$/
			  let urlRegex = /^((https|http|ftp|rtsp|mms|rtp|artc|rtmp|RTMP|rtmp|RTMPS|rtmps|SRT|srt|ARTC|artc)?:\/\/|www\.)[^\s]+/;
			  for(let item of this.frequentPlan.nameUrls){
			    if(!urlRegex.test(item.url)){
			      canSubmit = false;
			    }
					if(!sk.test(item.url)){
						canSubmit = false;
					}
			  }
			  if(canSubmit){
			    plan.solAdd(this.frequentPlan).then(res => {
			      this.$message({
			        message: this.$t('lang.addsuccess'),
			        type: 'success'
			      });
			      this.getAllPlan()
			    })
			  }else{
			    this.$message.error(this.$t('lang.Pleaseenter'));
			    return
			  }
			}
		}
	}
</script>

<style lang="less" scoped>
	.contenst{
		padding-left: .6rem;
		.header{
			padding-top: .2rem;
			font-size: .16rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: #307B37;
			span:first-child{
				display: inline-block;
				width: .04rem;
				height: .2rem;
				background-color: #307B37;
				border-radius: 2px;
				margin-right: .07rem;
			}
		}
	}
	.plan{
		padding: .3rem 0 0 0;
		display: flex;
		flex-flow: column;
		.box-title{
			display: flex;
			align-items: center;
			font-size: .14rem;
			color: #666;
			.box-titlea{
				height: .34rem;
				background-color: #2D2D2D;
				border: 1px solid #909090;
				border-radius: 2px;
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
				color: #fff;
				padding: 0 10px;
				margin-right: 20px;
				img{
					height: .15rem;
					margin-right: .04rem;
				}
			}
		}
		.item-box{
				width: 80%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
		  /deep/.plan-item{
			  margin-top: .2rem;
				margin-bottom: .2rem;
			  .plan-items{
				  display: flex;
				  flex-flow: column;
				  justify-content: flex-start;
					background-color: #1F2224;
				  width: 5.42rem;
				  border-radius: 4px;
					.el-form-item__label{
						color: #C2C2C2 !important;
						width: 1.1rem;
					}
					.planads{
						.planName-box{
							display: flex;
							margin-bottom: 22px;
						}
						.del-icon{
						  // width: .40rem;
						  // height: .40rem;
						  // border-radius: 50%;
						  // background-color: #0A0F30;
						  // text-align: center;
						  margin-left: .13rem;
						  position: relative;
						  img{
							width: .26rem;
							position: absolute;
							left: .08rem;
							top: .06rem;
						  }
						}
					}
					.el-input{
						.el-input__inner{
						  background-color: #222628;
						  border-color:#909090;
						}
					}
				  .el-form{
					  padding: .2rem .2rem 0 .2rem;
				  }
				  .address-item{
					display: flex;
					.el-form-item{
					   display: flex;
					}
					.del-icon{
					  // width: .40rem;
					  // height: .40rem;
					  // border-radius: 50%;
					  // background-color: #0A0F30;
					  // text-align: center;
					  margin-left: .13rem;
					  position: relative;
					  img{
						width: .26rem;
						position: absolute;
						left: .08rem;
						top: .06rem;
					  }
					}
				  
				  }
			  }
		  }
		}
	}
	.active{
		background-color: #307B37 !important;
		border: none !important;
	}
	.addbutton{
		padding: 0;
		margin-top: .36rem;
		color: #fff;
		width: 1.2rem;
		height: .34rem;
		background-color: #171B1E;
		border: 1px solid #909090;
	}
	.addbuttons{
		margin-bottom: .2rem;
		margin-left: 1.3rem;
		padding: 0;
		color: #fff;
		width: 1.2rem;
		height: .34rem;
		background-color: #1F2224;
		border: 1px solid #909090;
	}
	.bottom-btn{
		border-top: 1px solid #2A2E2D;
		display: flex;
		justify-content: flex-end;
		padding-right: .5rem;
		padding-bottom: .2rem;
		.el-button{
			margin-top: .2rem;
			background-color: #1F2224;
			border: 1px solid #909090;
			color: #fff;
			&:last-of-type{
				background-color: #307B37;
				border: none;
			}
		}
	}
</style>
