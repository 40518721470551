<template>
  <div class="content">
    <div class="heder">
      <div class="left" v-if="filg == 5">
        <div class="userxxs">
          <div class="usernames">
            <img :src="headerImg" />
            <span style="margin-bottom: 0.17rem">{{ userxx.email }}</span>
            <span
              style="
                color: #307b37;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="revisepas"
              >{{ $t("lang.ChangePassword") }}</span
            >
          </div>
          <div class="balance">
            <div>
              <span>{{ $t("lang.accountbalances") }}：￥</span>
              <span>{{ balancess }}</span>
            </div>
            <div class="recharge" @click="rechs">
              <span style="cursor: pointer">{{
                $t("lang.Balancerecharge")
              }}</span>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="orde" style="margin-top: 0.2rem">
            <div
              v-for="(item, index) in list"
              :key="index"
              :class="index == filg ? 'order_s' : ''"
              @click="swites(index)"
            >
              <img v-if="index == filg" :src="item.imgs" />
              <img v-else :src="item.img" />
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div @click="devicelist" class="devis">
            <img src="@/assets/imgs/devlis/009.png" />
            <span>{{ $t("lang.BackHome") }}</span>
          </div>
        </div>
        <div class="left_bott" @click="signOuts">
          {{ $t("lang.signout") }}
        </div>
      </div>
      <div class="right" :style="filg == 5?'margin: 0.15rem;':''">
        <plantobuy v-if="filg == 0"></plantobuy>
        <orders v-if="filg == 1"></orders>
        <bills v-if="filg == 2 && inds == 5"></bills>
        <scheme v-if="filg == 3"></scheme>
        <userse v-if="filg == 4"></userse>
        <rechage v-if="filg == 5"></rechage>
        <check v-if="filg == 2 && inds == 0"></check>
        <flow v-if="filg == 2 && inds == 1"></flow>
      </div>
    </div>
    <!-- 退出登录 -->
    <el-dialog
      :visible.sync="backlogin"
      width="5rem"
      :title="$t('lang.Logout')"
      :show-close="false"
      custom-class="backlogin"
    >
      <p>{{ $t("lang.Doyouwanttologout") }}</p>
      <div class="submitpas">
        <button @click="backlogin = false">
          <p>{{ $t("lang.cancel") }}</p>
        </button>
        <button @click="backLogin">
          <p>{{ $t("lang.determine") }}</p>
        </button>
      </div>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      :visible.sync="showpas"
      width="30%"
      top="1rem"
      :title="$t('lang.ChangePassword')"
      :show-close="false"
      custom-class="setpas"
      center
    >
      <el-form :model="formVal" :rules="rules" ref="ruleForm">
        <el-form-item :label="$t('lang.accountnumber') + '：'">
          <p>{{ emailval }}</p>
        </el-form-item>
        <el-form-item
          :label="$t('lang.Originalpassword')"
          prop="oldpasval"
          class="inputd"
        >
          <el-input
            type="password"
            v-model="formVal.oldpasval"
            :type="pwdInputType"
            maxlength="16"
            style="width: 3rem"
          ></el-input>
          <img
            class="eyes-icon"
            :src="eyeIcon"
            @click="handleShowPwd(1)"
            style="cursor: pointer"
          />
        </el-form-item>
        <el-form-item
          :label="$t('lang.Newpassword')"
          prop="newpasval"
          class="inputd"
        >
          <el-input
            type="password"
            v-model="formVal.newpasval"
            :type="pwdInputTypes"
            maxlength="16"
            style="width: 3rem"
          ></el-input>
          <img
            class="eyes-icon"
            :src="eyeIcons"
            @click="handleShowPwd(2)"
            style="cursor: pointer"
          />
        </el-form-item>
        <el-form-item
          :label="$t('lang.Confirmpassword')"
          prop="conpasval"
          class="inputd"
        >
          <el-input
            type="password"
            v-model="formVal.conpasval"
            :type="pwdInputTypesa"
            maxlength="16"
            style="width: 3rem"
          ></el-input>
          <img
            class="eyes-icon"
            :src="eyeIconsa"
            @click="handleShowPwd(3)"
            style="cursor: pointer"
          />
        </el-form-item>
      </el-form>
      <div class="submitpas">
        <button @click="showpas = false" style="margin-left: 1.4rem">
          <p>{{ $t("lang.cancel") }}</p>
        </button>
        <button @click="submitpas('ruleForm')">
          <p>{{ $t("lang.submit") }}</p>
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as nav from "@/api/apis";
import { getTime } from "@/utils/time.js";
import JSEncrypt from "jsencrypt";
import userse from "./component/user";
import scheme from "./component/pushscheme";
import rechage from "./component/recharge";
import orders from "./component/order";
import bills from "./component/bill";
import check from "./component/check";
import flow from "./component/flowbill";
import plantobuy from "./component/Plantobuy";
export default {
  components: {
    userse,
    scheme,
    rechage,
    orders,
    bills,
    check,
    flow,
    plantobuy,
  },
  data() {
    var newpasval = (rule, value, callback) => {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!value) {
        callback(new Error(this.$t("lang.Pleaseenteranewpassword")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t("lang.defPassword")));
      } else {
        callback();
      }
    };
    var conpasval = (rule, value, callback) => {
      if (value !== this.formVal.newpasval) {
        callback(new Error(this.$t("lang.PasswordNotMatch")));
      } else {
        callback();
      }
    };
    var newpasvals = (rule, value, callback) => {
      if (!value || value == "") {
        callback(new Error(this.$t("lang.Pleaseentertheoriginalpassword")));
      } else {
        callback();
      }
    };
    return {
      userxx: "",
      headerImg: require("@/assets/imgs/yhzcl.png"),
      username: "",
      filg: 5,
      inds: 5,
      backlogin: false,
      showpas: false,
      rules: {
        oldpasval: [
          {
            required: true,
            //message:this.$t("lang.Pleaseentertheoriginalpassword"),
            validator: newpasvals,
            trigger: "blur",
          },
        ],
        newpasval: [
          {
            required: true,
            validator: newpasval,
            trigger: "blur",
          },
        ],
        conpasval: [
          {
            required: true,
            //message: this.$t("lang.Pleaseentertheconfirmationpassword"),
            validator: conpasval,
            trigger: "blur",
          },
        ],
      },
      formVal: {
        oldpasval: "",
        newpasval: "",
        conpasval: "",
      },
      emailval: "",
      eyeIcon: require("@/assets/imgs/close_eyes.png"),
      eyeIcons: require("@/assets/imgs/close_eyes.png"),
      eyeIconsa: require("@/assets/imgs/close_eyes.png"),
      pwdInputType: "password",
      pwdInputTypes: "password",
      pwdInputTypesa: "password",
      usertimess: null,
      balancess: "",
    };
  },
  computed: {
    list: function () {
      return [
        // {
        //   text: this.$t("lang.PackageCenter"),
        //   img: require("@/assets/imgs/devlis/001.png"),
        //   imgs: require("@/assets/imgs/devlis/002.png"),
        // },{
        //   text: this.$t("lang.Ordercenters"),
        //   img: require("@/assets/imgs/devlis/001.png"),
        //   imgs: require("@/assets/imgs/devlis/002.png"),
        // },
        // {
        //   text: this.$t("lang.billingcenters"),
        //   img: require("@/assets/imgs/devlis/003.png"),
        //   imgs: require("@/assets/imgs/devlis/004.png"),
        // },
        // {
        //   text: this.$t("lang.Streamingscheme"),
        //   img: require("@/assets/imgs/devlis/005.png"),
        //   imgs: require("@/assets/imgs/devlis/006.png"),
        // },
        // {
        //   text: this.$t("lang.accountsettings"),
        //   img: require("@/assets/imgs/devlis/007.png"),
        //   imgs: require("@/assets/imgs/devlis/008.png"),
        // },
      ];
    },
  },
  watch: {
    $route: {
      handler: function () {
        if (this.$route.query.ind) {
          this.filg = this.$route.query.ind;
        }
        if (this.$route.query.inds) {
          this.inds = this.$route.query.inds;
        } else {
          this.inds = 5;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    //console.log(this.$route.query)
    if (this.$route.query.ind) {
      this.filg = this.$route.query.ind;
    }
    if (this.$route.query.inds) {
      this.inds = this.$route.query.inds;
    } else {
      this.inds = 5;
    }
    this.userxx = JSON.parse(sessionStorage.getItem("user"));
    if (this.userxx) {
      this.username = this.userxx.name;
      this.emailval = this.userxx.email;
      if (this.userxx.img) {
        this.headerImg = this.userxx.img;
      }
    }
    this.balancess = sessionStorage.getItem("balance");
    this.usertimess = setInterval(() => {
      this.balancess = sessionStorage.getItem("balance");
    }, 2000);
  },
  beforeDestroy() {
    if (this.usertimess) {
      clearInterval(this.usertimess);
    }
  },
  methods: {
    //切换列表
    swites(index) {
      this.filg = index;
      this.$router.push({
        path: "/nav/personal",
        query: { ind: index },
      });
    },
    //跳转充值页面
    rechs() {
      this.filg = 5;
      this.$router.push({
        path: "/nav/personal",
        query: { ind: 5 },
      });
    },
    //返回设备列表
    devicelist() {
      this.$router.push({
        path: "/nav/user/equipment",
      });
    },
    //打开退出模态框
    signOuts() {
      this.backlogin = true;
    },
    //退出登录
    backLogin() {
      this.backlogin = false;
      let times = new Date();
      let time = getTime(times);
      setTimeout(() => {
        sessionStorage.clear();
        this.$router.push("/userlogin");
      }, 1000);
      let email = this.userxx.email;
      let datas = { loginOutTime: time.time, email: email };
      nav.loginOut(datas).then((res) => {
        this.$message.success(this.$t("lang.Exitsuccessful"));
      });
    },
    //打开修改密码模态框
    revisepas() {
      this.showpas = true;
      this.eyeIcon = require("@/assets/imgs/close_eyes.png");
      this.eyeIcons = require("@/assets/imgs/close_eyes.png");
      this.eyeIconsa = require("@/assets/imgs/close_eyes.png");
      this.pwdInputType = "password";
      this.pwdInputTypes = "password";
      this.pwdInputTypesa = "password";
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
        for (let key in this.formVal) {
          this.formVal[key] = "";
        }
      });
    },
    //显示隐藏密码
    handleShowPwd(ind) {
      if (ind == 1) {
        this.pwdInputType =
          this.pwdInputType === "password" ? "text" : "password";
        this.eyeIcon =
          this.eyeIcon === require("@/assets/imgs/close_eyes.png")
            ? require("@/assets/imgs/kejian.png")
            : require("@/assets/imgs/close_eyes.png");
      } else if (ind == 2) {
        this.pwdInputTypes =
          this.pwdInputTypes === "password" ? "text" : "password";
        this.eyeIcons =
          this.eyeIcons === require("@/assets/imgs/close_eyes.png")
            ? require("@/assets/imgs/kejian.png")
            : require("@/assets/imgs/close_eyes.png");
      } else {
        this.pwdInputTypesa =
          this.pwdInputTypesa === "password" ? "text" : "password";
        this.eyeIconsa =
          this.eyeIconsa === require("@/assets/imgs/close_eyes.png")
            ? require("@/assets/imgs/kejian.png")
            : require("@/assets/imgs/close_eyes.png");
      }
    },
    //修改密码
    submitpas(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          const jse = new JSEncrypt();
          const publiceKey =
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
          jse.setPublicKey(publiceKey);
          let params = {
            email: this.userxx.email,
            password: jse.encrypt(this.formVal.oldpasval),
            newpassword: jse.encrypt(this.formVal.newpasval),
            passwordqr: jse.encrypt(this.formVal.conpasval),
          };
          nav.Upload(params).then((res) => {
            this.$message.success(this.$t("lang.again"));
            this.showpas = false;
            setTimeout(() => {
              sessionStorage.clear();
              this.$router.push("/userlogin");
            }, 2000);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #525255;
  //padding: .22rem .45rem .22rem .35rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.heder {
  display: flex;
  height: 100%;
  background-color: #565659;
  .left {
    font-size: 0.16rem;
    background-color: #ffffff;
    height: 100%;
    width: 2.6rem;
    border-radius: 2px;
    background-image: url("../../../../src/assets/imgs/bglist.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    box-shadow: 0px 8px 7px 0px rgba(0, 0, 0, 0.07);
    .left_bott {
      background-color: #307b37;
      text-align: center;
      color: #fff;
      margin: 0 0.16rem 0.2rem 0.16rem;
      padding: 0.1rem 0;
      cursor: pointer;
    }
    .userxxs {
      color: #c2c2c2;
      .usernames {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 0.4rem 0 0.15rem 0;
        box-sizing: border-box;
        border-bottom: 1px solid #181c1c;
        img {
          width: 0.7rem;
          height: 0.7rem;
          border-radius: 50%;
          margin-bottom: 0.2rem;
        }
      }
      .balance {
        background-color: #181c1c;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        height: 0.6rem;
        padding: 0 0.18rem;
        font-size: 0.14rem;
        .recharge {
          color: #307b37;
          width: 0.76rem;
          height: 0.3rem;
          line-height: 0.3rem;
          text-align: center;
          border: 1px solid #307b37;
          border-radius: 2px;
        }
      }
    }
    .center {
      color: #c2c2c2;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      height: 100%;
      .orde {
        div {
          padding: 0.1rem 0.18rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 0.16rem;
            margin-right: 0.1rem;
          }
        }
        .order_s {
          background-color: #181c1c;
          color: #307b37;
        }
      }
      .devis {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0.1rem 0.18rem;
        img {
          width: 0.17rem;
          margin-right: 0.1rem;
        }
      }
    }
  }
  .right {
    
    box-sizing: border-box;
    background-color: #171b1e;
    width: calc(100vw - 2.6rem);
    overflow-y: auto;
  }
}
/deep/.inputd {
  position: relative;
  .el-input__inner {
    padding: 0 0.4rem 0 0.15rem;
  }
  .eyes-icon {
    width: 0.22rem;
    height: 0.14rem;
    position: absolute;
    top: 0.15rem;
    right: 0.15rem !important;
  }
}
/deep/.setpas {
  .el-form-item {
    margin-bottom: 0.4rem;
  }
}
</style>
